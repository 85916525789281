import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "../../../ludz.one_ui";
import { TypeZoneSubzone } from "../../../ludzoneSDK/Zone/type";
import { AreaAvatar, IndicatorSubzone } from "../Zonas/style";
import { Container, ImageRoom } from "./style";

interface PropsSubzonaMenu {
  subzona?: TypeZoneSubzone;
  refetch: any
}

const SubzonasMenu = ({ subzona, refetch }: PropsSubzonaMenu) => {
  const navigate = useNavigate();
  const { idSubzone } = useParams();

  function linkHistory() {
    navigate(`/zona/${subzona?.zone_fk}/subzona/${subzona?.id}/painel`);
    window.location.reload();
  }

  // Determina se a subzona está selecionada com base no parâmetro da rota
  const isSelected = subzona?.id.toString() === idSubzone;

  
  return (
    <Container>
      
      <Tooltip title={subzona?.name} placement="right" arrow>
        <AreaAvatar>
        {isSelected && <IndicatorSubzone />}
        <ImageRoom onClick={linkHistory}>
          <img src={subzona?.aws?.url!} alt="" />
        </ImageRoom>
        </AreaAvatar>
      </Tooltip>
    </Container>
  );
};

export default SubzonasMenu;
